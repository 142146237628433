import ArrowRight from "@modules/common/icons/arrow-right"
import Link from "next/link"
import clsx from "clsx"

type UnderlineLinkProps = {
    href: string
    children?: React.ReactNode
    className?: string
}

const UnderlineLink = ({
    href,
    children,
    className = "",
}: UnderlineLinkProps) => {
    return (
        <div className="flex justify-center small:justify-start items-start">
            <Link href={href} prefetch={false}>
                <a
                    className={clsx(
                        "flex items-center text-large-regular border-b border-darkTone gap-x-4 py-2 transition-all duration-300 group hover:text-highlight hover:pl-4 hover:pr-1",
                        className ?? ""
                    )}
                >
                    <span className="font-mono">{children}</span>
                    <ArrowRight
                        size={20}
                        className="transition-all group-hover:ml-2 duration-300"
                    />
                </a>
            </Link>
        </div>
    )
}

export default UnderlineLink
